import "./Components.css";

const Footer = () => {
  return (
    <>
      <div className="footer-section">
        <div className="footer-division">
          <div className="developers">
            <h3>Developers</h3>
            <p>Dominic</p>
            <p>BackEnd Developer at Full Scale.</p>
          </div>
          <div className="links">
            <h3>Useful Links</h3>
            <a href="https://www.bing.com/ck/a?!&&p=67c04439422f4fdcJmltdHM9MTY2OTE2MTYwMCZpZ3VpZD0xZGQ2MzU3Yy01ZDZkLTY2YjktMDdlYi0yNDkxNWM3OTY3NDEmaW5zaWQ9NTE2NQ&ptn=3&hsh=3&fclid=1dd6357c-5d6d-66b9-07eb-24915c796741&psq=full+scale+gigabook&u=a1aHR0cHM6Ly9mdWxsLXNjYWxlLmdpZ2Fib29rLmNvbS8&ntb=1">
              Full Scale - GigaBook
            </a>
          </div>
        </div>
        <div className="main-details">
          <h4> Full Scale - GigaBook. All Rights Reserved 2022.</h4>
        </div>
      </div>
    </>
  );
};

export default Footer;
